export const tabs_ua = [
  'Про\u00A0БЦ',
  'Поверх\u00A01',
  'Поверх\u00A02',
  'Поверх\u00A03',
  'Поверх\u00A04',
  'Поверх\u00A05',
];

export const tabs_en = [
  'About',
  'Ground\u00A0floor',
  '1\u00A0floor',
  '2\u00A0floor',
  '3\u00A0floor',
  '4\u00A0floor',
];

export const plug_ua = {
  noSpace: ['/images/availableOffices/plugs/no-offices-ua.png'],
  noPhoto: ['/images/availableOffices/plugs/prepare-photos-ua.png'],
};

export const plug_en = {
  noSpace: ['/images/availableOffices/plugs/no-offices-en.png'],
  noPhoto: ['/images/availableOffices/plugs/prepare-photos-en.png'],
};

export const floors = [
  {
    name: 'Поверх\u00A01',
    plan: {
      ua: './images/floorPlans/floor-1-ua.png',
      en: './images/floorPlans/floor-1n-en.png',
    },
    images: [
      '/images/availableOffices/floor-1/A-1-1.jpg',
      '/images/availableOffices/floor-1/A-1-2.jpg',
      '/images/availableOffices/floor-1/A-1-3.jpg',
      '/images/availableOffices/floor-1/A-1-4.jpg',
    ],
  },
  {
    name: 'Поверх\u00A02',
    plan: {
      ua: './images/floorPlans/floor-2n.png',
      en: './images/floorPlans/floor-2n-en.png',
    },

    images: [
      //'/images/availableOffices/floor-2/B-1-1.jpg',
      //'/images/availableOffices/floor-2/B-1-2.jpg',
      //'/images/availableOffices/floor-2/B-1-3.jpg',
      //'/images/availableOffices/floor-2/B-1-4.jpg',
      // '/images/availableOffices/floor-2/B-9-1.jpg',
      // '/images/availableOffices/floor-2/B-9-2.jpg',
      // '/images/availableOffices/floor-2/B-9-3.jpg',
      // '/images/availableOffices/floor-2/B-9-4.jpg',
      // '/images/availableOffices/floor-2/B-12-2.jpg',
      // '/images/availableOffices/floor-2/B-12-3.jpg',
      // '/images/availableOffices/floor-2/B-16-1.jpg',
      // '/images/availableOffices/floor-2/B-16-2.jpg',
      // '/images/availableOffices/floor-2/B-16-3.jpg',
      // '/images/availableOffices/floor-2/B-16-4.jpg',
      // '/images/availableOffices/floor-2/B-16-5.jpg',
      // '/images/availableOffices/floor-2/B-16-6.jpg',
      // '/images/availableOffices/floor-2/B-16-7.jpg',
      // '/images/availableOffices/floor-2/B-16-8.jpg',
      '/images/availableOffices/floor-2/B-16-1n.png',
      '/images/availableOffices/floor-2/B-16-2n.png',
      '/images/availableOffices/floor-2/B-16-3n.png',
      '/images/availableOffices/floor-2/B-16-4n.png',
    ],
  },
  {
    name: 'Поверх\u00A03',
    plan: {
      ua: './images/floorPlans/floor-3n.png',
      en: './images/floorPlans/floor-3n-en.png',
    },
    images: [
      // '/images/availableOffices/floor-3/C-13-1.jpg',
      // '/images/availableOffices/floor-3/C-13-2.jpg',
      // '/images/availableOffices/floor-3/C-13-3.jpg',
      // '/images/availableOffices/floor-3/C-13-4.jpg',
      '/images/availableOffices/floor-3/C-1-5-1n.png',
      '/images/availableOffices/floor-3/C-1-5-2n.png',
      '/images/availableOffices/floor-3/C-1-5-3n.png',
      '/images/availableOffices/floor-3/C-1-5-4n.png',
      '/images/availableOffices/floor-3/C-6-9-1n.png',
      '/images/availableOffices/floor-3/C-6-9-2n.png',
      '/images/availableOffices/floor-3/C-6-9-3n.png',
      '/images/availableOffices/floor-3/C-6-9-4n.png',
      '/images/availableOffices/floor-3/C-18-1n.png',
      '/images/availableOffices/floor-3/C-18-2n.png',
      '/images/availableOffices/floor-3/C-18-3n.png',
      '/images/availableOffices/floor-3/C-18-4n.png',
    ],
  },
  {
    name: 'Поверх\u00A04',
    plan: {
      ua: './images/floorPlans/floor-4n.png',
      en: './images/floorPlans/floor-4n-en.png',
    },
    images: [
      // '/images/availableOffices/floor-4/plug.png',
      '/images/availableOffices/floor-4/D-8a-1n.png',
      '/images/availableOffices/floor-4/D-8a-2n.png',
      '/images/availableOffices/floor-4/D-10-11-1n.png',
      '/images/availableOffices/floor-4/D-10-11-2n.png',
      '/images/availableOffices/floor-4/D-10-11-3n.png',
      '/images/availableOffices/floor-4/D-10-11-4n.png',
      '/images/availableOffices/floor-4/D-12-1n.png',
      '/images/availableOffices/floor-4/D-12-2n.png',
      '/images/availableOffices/floor-4/D-12-3n.png',
      '/images/availableOffices/floor-4/D-12-4n.png',
      '/images/availableOffices/floor-4/D-13-14-1n.png',
      '/images/availableOffices/floor-4/D-13-14-2n.png',
      '/images/availableOffices/floor-4/D-13-14-3n.png',
      '/images/availableOffices/floor-4/D-13-14-4n.png',
      '/images/availableOffices/floor-4/D-16-1n.png',
      '/images/availableOffices/floor-4/D-16-2n.png',
      '/images/availableOffices/floor-4/D-16-3n.png',
      '/images/availableOffices/floor-4/D-16-4n.png',
      '/images/availableOffices/floor-4/D-17-1n.png',
      '/images/availableOffices/floor-4/D-17-2n.png',
      '/images/availableOffices/floor-4/D-17-3n.png',
      '/images/availableOffices/floor-4/D-17-4n.png',
      '/images/availableOffices/floor-4/D-18-1n.png',
      '/images/availableOffices/floor-4/D-18-2n.png',
      '/images/availableOffices/floor-4/D-18-3n.png',
      '/images/availableOffices/floor-4/D-18-4n.png',
    ],
  },
  {
    name: 'Поверх\u00A05',
    plan: {
      ua: './images/floorPlans/floor-5n.png',
      en: './images/floorPlans/floor-5n-en.png',
    },
    images: [
      '/images/availableOffices/floor-5/F-6-1.jpg',
      '/images/availableOffices/floor-5/F-6-2.jpg',
      '/images/availableOffices/floor-5/F-6-3.jpg',
      '/images/availableOffices/floor-5/F-11-1n.png',
      '/images/availableOffices/floor-5/F-11-2n.png',
      '/images/availableOffices/floor-5/F-11-3n.png',
      '/images/availableOffices/floor-5/F-12-1n.png',
      '/images/availableOffices/floor-5/F-12-2n.png',
      '/images/availableOffices/floor-5/F-13-14-1n.png',
      '/images/availableOffices/floor-5/F-13-14-2n.png',
      '/images/availableOffices/floor-5/F-13-14-3n.png',
      '/images/availableOffices/floor-5/F-13-14-4n.png',
      '/images/availableOffices/floor-5/F-16-17-1n.png',
      '/images/availableOffices/floor-5/F-16-17-2n.png',
      '/images/availableOffices/floor-5/F-16-17-3n.png',
      '/images/availableOffices/floor-5/F-16-17-4n.png',
      // '/images/availableOffices/floor-5/F-9-4.jpg',
      // '/images/availableOffices/floor-5/F-9-1.jpg',
      // '/images/availableOffices/floor-5/F-9-2.jpg',
      // '/images/availableOffices/floor-5/F-9-3.jpg',
      // '/images/availableOffices/floor-5/F-15-4.jpg',
      // '/images/availableOffices/floor-5/F-15-1.jpg',
      // '/images/availableOffices/floor-5/F-15-2.jpg',
      // '/images/availableOffices/floor-5/F-15-3.jpg',
      '/images/availableOffices/floor-5/F-18-2.jpg',
      '/images/availableOffices/floor-5/F-18-3.jpg',
      '/images/availableOffices/floor-5/F-18-1.jpg',
    ],
  },
];
