export const gallery = [
  '/images/gallery/2.jpg',
  '/images/gallery/4.jpg',
  '/images/gallery/1.jpg',
  '/images/gallery/3.jpg',
  '/images/gallery/5-2.jpg',
  '/images/gallery/6.jpg',
  '/images/gallery/7.jpg',
  '/images/gallery/8.jpg',
  '/images/gallery/9.jpg',
  '/images/gallery/10.jpg',
];
